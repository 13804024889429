/* Properties for Microsoft Logo */
.m-st0 {
    fill: #222;
    .dark-theme & {
      fill: #a9a9b3;
    }
}
.m-st1 {
    fill: #F1511B;
}
.m-st2 {
    fill: #80CC28;
}
.m-st3 {
    fill: #00ADEF;
}
.m-st4 {
    fill: #FBBC09;
}

/* Properties for Amazon Logo */
.a-st0 {
    fill: #FF9900;
}
.a-st1 {
    fill: #222;
    .dark-theme & {
      fill: #a9a9b3;
    }
}